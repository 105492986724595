import { useMutation } from 'react-query'

import { ErrorResponse } from 'services/error-response'
import httpClient from 'services/http-client'

export const PUT_CART_ITEMS = '/cart/items'

type CartItemsDetails = {
  variantKey: string
  key: string
  quantity: number
}

const putCartItems = async (form: CartItemsDetails) => {
  return await httpClient.put(PUT_CART_ITEMS, form)
}

export const usePutCartItems = (onError: (errors: ErrorResponse) => void) => useMutation(putCartItems, { onError })
