import { useMemo } from 'react'

import cn from 'classnames'

import { RegularClose } from 'components/icon/regular'
import SvgRegularMinus from 'components/icon/regular/RegularMinus'
import SvgRegularPlus from 'components/icon/regular/RegularPlus'
import ImageComponent from 'components/image'
import Link from 'components/link'
import Select from 'components/select'
import Toast from 'components/toast'
import { DEFAULT_PRODUCT_QUANTITY_MAX_IN_CART } from 'features/cart/constants'

import { useTranslate } from 'hooks'

import { formatPrice } from 'utils/functions'

import css from './cart-item.module.scss'

type CartItemProps = {
  sku: string
  brandKey: string
  productKey: string
  picture: string
  name: string
  caseQuantity: number
  quantity?: number
  price: number
  currency: string
  minOrderQuantity: number
  maxOrderQuantity: number
  onIconClick: (
    increment: boolean,
    productKey: string,
    variantKey: string,
    currentQuantity: number,
    maxQuantity: number
  ) => void
  onChange: (brandKey: string, sku: string, currentQuantity: number, newQuantity: number) => void
  onRemove: (brandKey: string, sku: string) => void
  isClickDisabled: boolean
  variant?: string | null
  variantKey: string
  isOutOfStock?: boolean
}

export const CartItem = ({
  brandKey,
  sku,
  picture,
  name,
  caseQuantity,
  quantity = 1,
  onChange,
  onRemove,
  price,
  currency,
  isClickDisabled,
  minOrderQuantity,
  maxOrderQuantity,
  onIconClick,
  variant,
  variantKey,
  productKey,
  isOutOfStock = false,
}: CartItemProps) => {
  const productQuantityOption = useMemo(() => {
    return Array.from({ length: maxOrderQuantity || DEFAULT_PRODUCT_QUANTITY_MAX_IN_CART }, (_, index) => index).map(
      (num: number) => ({
        label: (num + 1).toString(),
        value: num + 1,
      })
    )
  }, [maxOrderQuantity])

  const { t } = useTranslate('common')
  return (
    <div className={cn(css.container)}>
      <div className={css.image}>
        <ImageComponent src={picture} alt={name} width={60} height={60} />
      </div>
      <div className={css.content}>
        <div>
          <h4>
            <Link href={`/product/${productKey}`}>
              {name}
              {variant ? `- ${variant}` : ''}
            </Link>
          </h4>
          {!!isOutOfStock && <Toast type={'alert'} messages={[t('cart.remove_from_cart')]} hidden={false} />}
          {!isOutOfStock && (
            <div className={css.counter}>
              <div className={css.quantityChanger}>
                <SvgRegularMinus
                  fontSize={30}
                  style={{ cursor: 'pointer', marginTop: '8px', color: 'black' }}
                  // eslint-disable-next-line @typescript-eslint/no-meaningless-void-operator
                  onClick={() => onIconClick(false, productKey, variantKey, quantity, maxOrderQuantity)}
                />
                <Select
                  name="quantity"
                  value={{ label: quantity.toString(), value: quantity }}
                  onChange={(value) => onChange(productKey, variantKey, quantity, value as number)}
                  options={productQuantityOption}
                  wrapperClassName={css.selectWrapper}
                  isDisabled={isClickDisabled}
                  minimizeOptionHeight={true}
                />
                <SvgRegularPlus
                  fontSize={30}
                  style={{ cursor: 'pointer', marginTop: '8px', color: 'black' }}
                  onClick={() => onIconClick(true, productKey, variantKey, quantity, maxOrderQuantity)}
                />
              </div>
              <span>{t('case_quantity', { caseQuantity })}</span>
            </div>
          )}
        </div>
        <div className={css.rightContent}>
          <RegularClose
            onClick={() => {
              if (!isClickDisabled) {
                onRemove(brandKey, variantKey)
              }
            }}
            style={{ cursor: 'pointer' }}
          />
          {!isOutOfStock && <span>{formatPrice(price, currency)}</span>}
        </div>
      </div>
    </div>
  )
}
