import { useRouter } from 'next/router'
import { useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import type { CartBrand, CartFinancial } from '@markato-shared/common-types'
import cn from 'classnames'

import Button from 'components/button'
import ImageComponent from 'components/image'
import { errorToast, successToast } from 'components/notification-toast'

import { useTranslate } from 'hooks'

import { ErrorResponse } from 'services/error-response'
import { formatPrice } from 'utils/functions'

import { BrandDetails } from '../brand-details'
import { useDeleteItemFromCart } from '../hooks/use-delete-item-from-cart'
import { usePutCartItems } from '../hooks/use-put-cart-items'
import { CartItem } from './item'
import css from './mini-cart.module.scss'

type MiniCartProps = {
  isOpened?: boolean
  brands: CartBrand[]
  currency: string
  financial?: CartFinancial
  closeCart: () => void
}

export const MiniCart = ({ isOpened = false, brands, currency, financial, closeCart }: MiniCartProps) => {
  const { t } = useTranslate('common')
  const router = useRouter()

  const onError = (errors: ErrorResponse) => {
    errorToast(t(`error.${errors.response.data.code[0]}`))
  }

  const onSuccessRemoveFromCart = () => {
    successToast(t('successfully_removed'))
  }

  useEffect(() => {
    if (brands.length === 0) {
      closeCart()
    }
  }, [brands, closeCart])

  const { mutateAsync: deleteCartItems, isLoading: isLoadingDelete } = useDeleteItemFromCart(
    onError,
    onSuccessRemoveFromCart
  )
  const { mutateAsync: changeCartItemQuantity, isLoading: isLoadingUpdateQuantity } = usePutCartItems(onError)

  const onChange = (productKey: string, variantKey: string, currentQuantity: number, newQuantity: number) => {
    if (newQuantity === currentQuantity) return
    void changeCartItemQuantity({ variantKey, key: productKey, quantity: newQuantity })
  }

  const onIconClick = (
    increment: boolean,
    productKey: string,
    variantKey: string,
    currentQuantity: number,
    maxQuantity: number
  ) => {
    if (!increment && currentQuantity === 1) return
    if (increment && currentQuantity === maxQuantity) return
    if (increment) {
      void changeCartItemQuantity({ variantKey, key: productKey, quantity: currentQuantity + 1 })
    }
    if (!increment) {
      void changeCartItemQuantity({ variantKey, key: productKey, quantity: currentQuantity - 1 })
    }
  }

  const onRemove = async (brandKey: string, variantKey: string) => {
    await deleteCartItems({ brandKey, variantKey })
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        closeCart()
      }}
    >
      <div className={cn(css.container, isOpened ? css.opened : css.hidden)}>
        <div className={css.header}>
          <p>
            {t('cart.brands')} ({brands.length})
          </p>
          <ImageComponent src="/images/icons/mobile-close.svg" alt={''} width={24} height={24} onClick={closeCart} />
        </div>
        <div className={css.content}>
          {brands.map((brand: CartBrand) => (
            <div key={brand.details.key} className={css.brandItem}>
              <BrandDetails
                brandKey={brand.details.key}
                image={brand.details.image ?? ''}
                name={brand.details.name}
                currency={currency}
                min={brand.financial.minAmount}
                spent={brand.financial.subtotal}
              />
              {brand.items.map((item) => (
                <CartItem
                  brandKey={brand.details.key}
                  key={item.sku}
                  sku={item.sku}
                  productKey={item.key}
                  picture={item.productUrl}
                  caseQuantity={item.caseQuantity}
                  quantity={item.quantity}
                  name={item.productName}
                  price={item.total}
                  currency={currency}
                  onChange={onChange}
                  onIconClick={onIconClick}
                  onRemove={onRemove}
                  variant={item.variantName}
                  variantKey={item.variantKey}
                  minOrderQuantity={item.minOrderQuantity}
                  maxOrderQuantity={item.maxOrderQuantity}
                  isClickDisabled={isLoadingDelete || isLoadingUpdateQuantity}
                  isOutOfStock={!item.isOnStock}
                />
              ))}
            </div>
          ))}
        </div>
        <div className={css.footer}>
          <div className={css.subtotal}>
            {t('cart.subtotal')}:{' '}
            <span className={css.subtotal}>{formatPrice(financial?.subtotalOfAllProductsInCart ?? 0, currency)}</span>
          </div>
          <Button
            data-test-id="mini-cart-checkout-button"
            label={t('cart.view_bag')}
            onClick={() => {
              void router.push('/retailer/cart')
              closeCart()
            }}
          />
        </div>
      </div>
    </OutsideClickHandler>
  )
}
