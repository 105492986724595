const subpaths = {
  HK: {
    EN: '/en-hk',
    ZH: '/zh-hk',
  },
  SG: {
    EN: '/en-sg',
    ZH: '/zh-sg',
  },
  MY: {
    EN: '/en-my',
    ZH: '/zh-my',
  },
}

module.exports = {
  markatoConfig: {
    cfCountries: {
      HK: 'HK',
      SG: 'SG',
      MY: 'MY',
    },
    subpaths: subpaths,
  },
  locales: ['en', 'en-hk', 'zh-hk', 'en-sg', 'zh-sg', 'en-my', 'zh-my'],
  alternateRefs: [
    { hreflang: 'en', subpath: subpaths.HK.EN },
    { hreflang: 'en-HK', subpath: subpaths.HK.EN },
    { hreflang: 'zh-Hant-HK', subpath: subpaths.HK.ZH },
    { hreflang: 'en-SG', subpath: subpaths.SG.EN },
    { hreflang: 'zh-Hans-SG', subpath: subpaths.SG.ZH },
    { hreflang: 'en-MY', subpath: subpaths.MY.EN },
    { hreflang: 'zh-Hans-MY', subpath: subpaths.MY.ZH },
  ],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'landing-page', 'registration', 'retailer', 'backend', 'strapi-component-app-header'],
    '/portal': ['portal', 'orders'],
    '/brand/registration': ['portal', 'onboarding', 'strapi-brand-registration'],
    '/portal/product-preview/[id]': ['product', 'common', 'registration', 'retailer'],
    '/brand/registration/confirmation': ['strapi-brand-registration-confirmation'],
    '/product/[key]': ['product', 'landing-page', 'registration', 'retailer'],
    '/retailer': ['retailer', 'landing-page', 'registration', 'strapi-retailer', 'rewards', 'checkout'],
    '/retailer/verification/[type]/[username]': ['retailer'],
    '/retailer/registration': ['retailer', 'onboarding'],
    '/retailer/onboarding': ['onboarding'],
    '/retailer/onboarding/thank-you': ['onboarding', 'checkout'],
    '/retailer/orders': ['orders', 'landing-page', 'strapi-retailer-orders'],
    '/retailer/orders/[key]': ['orders', 'landing-page', 'checkout', 'strapi-retailer-orders'],
    '/retailer/orders/[key]/return-request': [
      'orders',
      'landing-page',
      'checkout',
      'strapi-retailer-orders-[key]-return-request',
    ],
    '/retailer/orders/[key]/return-request/[requestId]/confirmation': ['orders', 'landing-page', 'checkout'],
    '/retailer/returns': ['orders', 'landing-page', 'strapi-retailer-returns'],
    '/retailer/returns/[key]': ['orders', 'landing-page'],
    '/retailer/orders/[key]/issues-report': [
      'orders',
      'landing-page',
      'checkout',
      'strapi-retailer-orders-[key]-issues-report',
      'portal',
    ],
    '/retailer/orders/[key]/issues-report/[requestId]/confirmation': [
      'orders',
      'landing-page',
      'checkout',
      'strapi-retailer-orders-[key]-issues-report',
    ],
    '/retailer/checkout': ['retailer', 'checkout', 'cart', 'landing-page'],
    '/retailer/payment-terms-apply': ['common', 'registration', 'retailer', 'landing-page', 'checkout', 'portal'],
    '/retailer/order-summary/[orderId]': ['retailer', 'checkout', 'cart', 'landing-page', 'orders'],
    '/retailer/verification/confirmation': ['retailer'],
    '/retailer/cart': ['cart', 'landing-page', 'checkout'],
    '/retailer/wallet': ['retailer', 'landing-page'],
    '/retailer/payment-terms': ['retailer', 'landing-page', 'checkout', 'portal'],
    '/retailer/credit-cards': ['retailer', 'landing-page', 'checkout'],
    '/retailer/pending-bank-transfers': ['retailer', 'checkout', 'landing-page'],
    '/retailer/transactions': ['retailer', 'landing-page'],
    '/retailer/rewards': ['rewards', 'checkout'],
    '/retailer/favourites': ['landing-page', 'retailer'],
    '/retailer/settings': ['retailer', 'landing-page'],
    '/retailer/settings/notifications': ['retailer', 'landing-page'],
    '/retailer/settings/billing-addresses': ['retailer', 'landing-page'],
    '/retailer/settings/shipping-addresses': ['retailer', 'landing-page'],
    '/retailer/settings/upload-documents': ['retailer', 'landing-page', 'portal', 'strapi-retailer-settings-upload-documents'],
    '/': ['common', 'retailer', 'registration', 'landing-page', 'strapi-home'],
    '/search/[...terms]': ['search', 'landing-page', 'retailer', 'registration', 'strapi-search-[...term]'],
    '/search/[term]': ['search', 'landing-page', 'retailer', 'registration'],
    '/brand/products/[key]': ['portal', 'retailer', 'registration'],
    '/brand/products/new': ['portal', 'retailer', 'registration'],
    '/brand/products': ['portal', 'retailer', 'registration'],
    '/brand/orders/[key]': ['portal'],
    '/brand/orders': ['portal', 'orders'],
    '/brand/orders/financial-breakdown/[id]': ['portal'],
    '/brand/profile': ['portal'],
    '/brand/notifications': ['portal'],
    '/brand/settings': ['portal'],
    '/brand/help': ['portal'],
    '/brand/[brandKey]/preview': ['portal', 'product', 'strapi-brand-[brandKey]'],
    '/portal/products/[key]': ['portal', 'retailer', 'registration', 'strapi-portal-products'],
    '/portal/products/new': ['portal', 'retailer', 'registration', 'strapi-portal-products'],
    '/portal/products': ['portal', 'retailer', 'registration', 'strapi-portal-products'],
    '/portal/product-preview/[id]': ['portal', 'product'],
    '/portal/orders': ['portal', 'orders'],
    '/portal/orders/[key]': ['portal', 'strapi-retailer-orders'],
    '/portal/orders/financial-breakdown/[id]': ['portal'],
    '/portal/profile': ['portal', 'search', 'strapi-brand-[brandKey]'],
    '/portal/notifications': ['portal'],
    '/portal/settings': ['portal', 'retailer', 'strapi-portal-settings'],
    '/portal/marketing': ['portal', 'retailer'],
    '/portal/help': ['portal'],
    '/brand/[brandKey]': ['portal', 'product', 'strapi-brand-[brandKey]'],
    '/brand/embed-widget/[brandKey]': ['strapi-brand-embed-widget-'],
    '/brand/promotions': ['portal', 'retailer', 'registration'],
    '/portal/finances': ['portal', 'strapi-portal-finances'],
    '/portal/kyc': ['portal'],
    '/portal/kyc/submitted': ['portal'],
  },
  loggerEnvironment: 'both',
  // To provide an unofficail language fallback to the next-translate package
  loadLocaleFrom: (lang, ns) => {
    const defaultLocale = 'en'
    const defaultNs = require(`./locales/${defaultLocale}/${ns}.json`)
    if (lang === defaultLocale) return Promise.resolve(defaultNs)
    const localNs = require(`./locales/${lang}/${ns}.json`)
    return Promise.resolve({
      ...defaultNs,
      ...localNs,
    })
  },
}
